import React from 'react';
import Layout from '../../../components/layout';
import RegistrationFrom from '../../../components/registration-form/registration-from';
import SEO from '../../../components/seo';

export default function RegisterMOIAT(props) {
  return (
    <Layout>
      <SEO lang='en' title='#GMIS2022 - MOIAT' />
      <RegistrationFrom source='MOIAT' page='moiat' imageName='moiat-expo' imageAlt='moiat-expo' />
    </Layout>
  );
}
